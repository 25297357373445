/* PatientProfile.css */

.patient-profile {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  .patient-profile h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile-section {
    margin-bottom: 20px;
  }
  
  .profile-section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .profile-info p {
    font-size: 16px;
    margin: 5px 0;
  }

  
  /* Add additional styles as needed */
  