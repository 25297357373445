

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.hidediv {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.showdiv {
    visibility: visible;
    opacity: 1;
}

.required:after {
    content: '*';
    color: red;
    padding-left: 5px;
}

.shadowBox {
    border: 1px solid lightgray;
    padding: 30px;
    boxShadow: 3px 0px 5px 3px #e3e8e5;
}


/* .footer {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom:0;
    left:0
  } */

  /* .wrapper {
    min-height: 100%;
    position: relative;
  } */
  @media print {
    .print-only-table {
      display: table;
      margin: auto;
      transform: scale(1);
      transform-origin: 0 0;
    }
        @page {
          size: landscape;
        }
  }
  
  @media screen {
    .print-only-table {
      display: none;      
    }
  }


  .normalMenu{
    text-decoration-line:"none"  !important;
    color:black;
    
  }
  .activeMenu{
    text-decoration-line:"none"  !important;
    padding-bottom: 5px;
    border-bottom: solid 2px #1b6ec2; 
  }


  .custom-source-item:hover{
    color:white;
    background-color: gray;

  }
  
 
.pgn input {
  font: inherit;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #1e1c1c;
  width: 20rem;
  max-width: 100%;
}

.pgn-label label {
  padding: 0.5em;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: inline
}

.backdrop-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  z-index: 9999;
}

.table-cursor {
  * > .ant-table-row {
    cursor: pointer;
  }
}

.ant-checkbox-wrapper{
  font-size: 1rem;
}

 .no-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
 }


.md .ant-table-thead >tr>th
{
  background-color: #f58220!important;
  color:white!important
}

/* table */
/* .md .ant-table-cell {
  font-size: 14px;
} */

/* row data */
 .md .ant-table-tbody > tr > td {
  height: 9px;
  padding: 10px;
} 
/* row tags */
/* .md .my-tag {
  font-size: 14px;
} */

 .md .ant-table-thead > tr > th {
  height: 9px;
  padding: 10px;
} 

.table-striped-rows tr:nth-child(2n) td {
  background-color: whitesmoke;
}

.table-striped-rows thead {
  background-color: darkgray;
}

.ccmd-checkbox-group .ant-checkbox-wrapper {
  line-height: 1.2;
}

.ccmd-checkbox-group .ant-checkbox {
  align-self: start;
  margin-top: 2px;
  line-height: 1;
}
